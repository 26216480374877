.smiley-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: pulse-opacity 6s ease-in-out infinite;
}

.smiley-overlay-content {
    height: 100vh;
    width: 100vw;
    animation: pulse 4s ease-in-out infinite, rotate 10s linear infinite, anit 10s linear infinite;
    box-sizing: content-box;
    border: none;
    font: normal 16px / normal "Times New Roman", Times, serif;
    color: rgba(0, 0, 0, 0.9);
    text-overflow: clip;
    background: linear-gradient(45deg, rgba(255, 0, 0, 0) 0, rgba(255, 0, 0, 0) 49%, rgb(25 79 157) 49%, rgb(0 229 255) 51%, rgba(255, 0, 0, 1) 51%, rgba(255, 0, 0, 0) 51%, rgba(255, 0, 0, 0) 100%), linear-gradient(135deg, rgba(255, 0, 0, 0) 0, rgba(255, 0, 0, 0) 49%, rgb(0 85 255) 49%, rgba(255, 0, 0, 1) 51%, rgba(255, 0, 0, 1) 51%, rgba(255, 0, 0, 0) 51%, rgba(255, 0, 0, 0) 100%);
    background-position: -5px 0, 5px 0;
    background-origin: padding-box;
    background-clip: border-box;
    background-size: 20px 10px;
}

@keyframes anit {
    from {background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;}
    to {background-position: 10 0, 30 0, 90px 10px, 30px 110px, 10 0, 20px 30px;}
}


@keyframes pulse {
    0%, 100% {
        clip-path: circle(3% at 50% 50%);
    }
    50% {
        clip-path: circle(33% at 50% 50%);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes pulse-opacity {
    0%, 50% {
  
        opacity: 1;
    }
    100% {

        opacity: 0.3;
    }
}

.eye {
  position: absolute;
  width: 100px;
  height: 100px;
  background-image: url('../../pictures/giphy.gif');
  background-size: cover;
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}

.left-eye {
  top: 30%;
  left: 35%;
}

.right-eye {
  top: 30%;
  right: 35%;
}

.mouth {
  position: absolute;
  width: 200px;
  height: 100px;
  background-image: url('../../pictures/gif.gif');
  background-size: cover;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
  clip-path: polygon(0% 0%, 100% 48%, 100% 0%, 0 48%);
}

.tooth {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: white;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  z-index: 1000;
}

.left-tooth {
  left: 35%;
  transform: translateX(-50%);
}

.right-tooth {
  right: 35%;
  transform: translateX(50%);
}

.typing-text {
  font-family: monospace;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid #000;
  width: 20ch;
  color: white;
  animation: typing 3s steps(11), blink 0.5s step-end infinite alternate;
  background: linear-gradient(to right, white 0%, white 0%, black 0%, black 100%);
  -webkit-background-clip: text;
  font-family: "Rubik Mono One", monospace;

}

@keyframes typing {
  from { 
    width: 0;
    background: linear-gradient(to right, white 0%, white 0%, black 0%, black 100%);
  }
  to { 
    width: 11ch;
    background: linear-gradient(to right, white 100%, white 100%, black 100%, black 100%);
  }
}

@keyframes blink {
  50% { border-color: transparent }
}
