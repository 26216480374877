.instagram-container {
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;
}

.instagram-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
}

.instagram-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-evenly;
    max-height: calc((150px * 3) + (1rem * 2)); /* Height for 3 rows + 2 gaps */
    overflow: scroll;

}

.instagram-post {
    flex: 0 0 150px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.instagram-post:hover {
    transform: translateY(-5px);
}

.instagram-post img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    display: block;
}

.instagram-container.loading,
.instagram-container.error {
    text-align: center;
    padding: 3rem;
    color: #666;
    font-size: 1.2rem;
}

.instagram-container.error {
    color: #dc3545;
}

.instagram-post video {
    width: 100%;
    height: 150px;
    object-fit: cover;
    display: block;
}

@media (max-width: 768px) {
    .instagram-post {
        flex: 0 0 120px;
    }

    .instagram-post img {
        height: 120px;
    }

    .instagram-post video {
        height: 120px;
    }
} 