.scene {
  width: 100px;
  height: 100px;
  perspective: 200px;
  margin: 0 auto;
  font-family: monospace;
  margin-bottom: 15px;
}

.cube {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-50px);
  transition: transform 0.1s;
}

.cube__face {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 1px solid black;
  background-size: cover;
  background-position: center;
}

.cube__face--front  { transform: rotateY(  0deg) translateZ(50px); }
.cube__face--right  { transform: rotateY( 90deg) translateZ(50px); }
.cube__face--back   { transform: rotateY(180deg) translateZ(50px); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(50px); }
.cube__face--top    { transform: rotateX( 90deg) translateZ(50px); }
.cube__face--bottom { transform: rotateX(-90deg) translateZ(50px); }

.project-cube {
  margin-bottom: 2rem;
}

.project-title {
  margin-top: 25px;
  margin-bottom: 20px;
  text-align: center;
}

.project-info-container {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.project-info-container.expanded {
  height: 300px;
}

.project-info {
  overflow-y: auto;
  max-height: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 1rem;
  font-family: "Rubik Mono One", monospace;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0.5rem;
}

.project-info p {
  margin-bottom: 1rem;
  font-family: "Rubik Mono One", monospace;
}

.project-info ul {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  
}

.project-info a {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.project-info a:hover {
  background-color: #0056b3;
}