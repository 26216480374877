.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background: var(--background-color, #1a1a1a);
  background-image: url('../../pictures/giphy.gif');
}

.login-form {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  backdrop-filter: blur(10px);
}

.login-form h2 {
  color: var(--text-color, #ffffff);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  color: var(--text-color, #ffffff);
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.form-group input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-color, #ffffff);
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: var(--accent-color, #4a90e2);
}

.error-message {
  color: #ff4444;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.9rem;
}

button[type="submit"] {
  width: 100%;
  padding: 0.8rem;
  background: var(--accent-color, #000000);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background: var(--accent-hover-color, #4c4e4f);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .login-form {
    padding: 1.5rem;
  }

  .login-form h2 {
    font-size: 1.5rem;
  }

  .form-group input {
    padding: 0.6rem;
  }
}

@media (max-width: 480px) {
  .login-container {
    padding: 15px;
  }

  .login-form {
    padding: 1rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }
} 