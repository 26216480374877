.admin-container {
  min-height: 100vh;
  background: var(--background-color, #1a1a1a);
  /* background-image: url('../../pictures/giphy.gif'); */
}

.admin-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 10;
}

.header-content {
  max-width: 1024px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.header-left h1 {
  color: var(--text-color, #ffffff);
  font-size: 1.8rem;
}

.tab-buttons {
  display: flex;
  gap: 1rem;
}

.tab-button {
  color: var(--text-color, #ffffff);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.8rem;
  font-size: 1rem;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.tab-button:hover {
  opacity: 0.8;
}

.tab-button.active {
  opacity: 1;
}

.logout-button {
  padding: 0.8rem 1.2rem;
  background: var(--accent-color, #000000);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background: var(--accent-hover-color, #4c4e4f);
}

.admin-content {
  max-width: 1024px;
  margin: 0 auto;
  padding: 5rem 1rem 2rem;
}

.error-message {
  color: #ff4444;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
  background: rgba(255, 68, 68, 0.1);
  padding: 0.8rem;
  border-radius: 4px;
  backdrop-filter: blur(5px);
}

.admin-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.grid-left, .grid-right {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

@media (min-width: 768px) {
  .admin-grid {
    grid-template-columns: 2fr 3fr;
  }
}

@media (max-width: 768px) {
  .header-left h1 {
    font-size: 1.5rem;
  }
  
  .grid-left, .grid-right {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .header-content {
    padding: 0.8rem;
  }
  
  .grid-left, .grid-right {
    padding: 1rem;
  }
}

.admin-page {
  min-height: 100vh;
  background: black;
}

.main-content {
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.admin-error {
  margin-bottom: 1.5rem;
  font-family: monospace;
  padding: 0.75rem;
  background: rgba(255, 68, 68, 0.05);
  border: 1px solid rgba(255, 68, 68, 0.1);
  border-radius: 0.25rem;
  color: #ff6b6b;
  font-size: 0.875rem;
  text-align: center;
}

.content-grid {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.post-section {
  flex: 2;
}

.form-section {
  flex: 3;
}

.tab-button {
  font-family: monospace;
  font-size: 0.875rem;
  transition: color 0.3s ease;
}

.tab-button.active {
  color: rgba(255, 255, 255, 0.9);
}

.tab-button:not(.active) {
  color: rgba(255, 255, 255, 0.4);
}

.tab-button:not(.active):hover {
  color: rgba(255, 255, 255, 0.6);
}

@media (min-width: 768px) {
  .content-grid {
    flex-direction: row;
  }
}

.post-list-container {
  position: sticky;
  top: 5rem;
}

.post-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.post-list-title {
  font-family: monospace;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.6);
}

.new-post-button {
  font-family: monospace;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
  transition: all 0.2s;
}

.new-post-button:hover {
  color: rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.05);
}

.posts-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
  padding-right: 0.5rem;
}

.post-item {
  padding: 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid transparent;
}

.post-item:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.1);
}

.post-item.selected {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

.post-item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
}

.post-title {
  font-family: monospace;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.9);
}

.post-type {
  font-family: monospace;
  font-size: 0.625rem;
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.4);
}

.post-content {
  margin-top: 0.25rem;
  font-family: monospace;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.4);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  padding: 3rem 0;
}

.spinner {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top-color: rgba(255, 255, 255, 0.9);
  animation: spin 1s linear infinite;
}

.empty-state {
  text-align: center;
  padding: 3rem 0;
}

.empty-state-title {
  font-family: monospace;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.4);
}

.empty-state-subtitle {
  font-family: monospace;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.2);
  margin-top: 0.25rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.form-container {
  position: sticky;
  top: 5rem;
}

.form-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.form-input {
  width: 100%;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.95);
  color: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transition: all 0.2s;
}

.form-input:focus {
  outline: none;
  border-color: rgba(16, 185, 129, 0.5);
  box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.2);
}

.form-input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.form-select {
  flex: 1;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.95);
  color: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transition: all 0.2s;
}

.form-url-input {
  flex: 2;
}

.form-textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.95);
  color: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  resize: none;
  min-height: 12rem;
  transition: all 0.2s;
}

.button-row {
  display: flex;
  gap: 0.75rem;
}

.submit-button {
  flex: 1;
  padding: 0.75rem 1.5rem;
  background: #000000;
  color: white;
  font-weight: 500;
  border-radius: 0.5rem;
  transition: all 0.2s;
  position: relative;
}

.submit-button:hover {
  background: #333333;
}

.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.delete-button {
  padding: 0.75rem 1.5rem;
  background: rgba(239, 68, 68, 0.1);
  color: rgb(248, 113, 113);
  font-weight: 500;
  border: 1px solid rgba(239, 68, 68, 0.2);
  border-radius: 0.5rem;
  transition: all 0.2s;
}

.delete-button:hover {
  background: rgba(239, 68, 68, 0.2);
}

.cancel-button {
  padding: 0.75rem 1.5rem;
  background: rgba(17, 17, 17, 0.5);
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  transition: all 0.2s;
}

.cancel-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.loading-spinner-container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-text {
  opacity: 0;
}

.button-text-visible {
  opacity: 1;
} 