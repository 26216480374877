.subscribe-container {
    padding: 2rem;
    margin: 2rem auto;
    max-width: 600px;
    text-align: center;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    backdrop-filter: blur(10px);
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.1),
        0 1px 3px rgba(0, 0, 0, 0.08),
        0 0 0 1px rgba(255, 255, 255, 0.1) inset;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.subscribe-container:hover {
    transform: translateY(-2px);
    box-shadow: 
        0 6px 8px rgba(0, 0, 0, 0.15),
        0 3px 6px rgba(0, 0, 0, 0.1),
        0 0 0 1px rgba(255, 255, 255, 0.15) inset;
}

.subscribe-title {
    color: var(--text-primary);
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.subscribe-description {
    color: var(--text-secondary);
    margin-bottom: 1.5rem;
}

.subscribe-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}

.subscribe-input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
    color: var(--text-primary);
    font-size: 1rem;
}

.subscribe-input:focus {
    outline: none;
    border-color: var(--primary-color);
}

.subscribe-button {
    width: 100%;
    padding: 0.75rem 1.5rem;
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
}

.subscribe-button:hover {
    background: var(--primary-color-dark);
}

.subscribe-message {
    margin-top: 1rem;
    font-size: 0.9rem;
}

.subscribe-message.success {
    color: #4caf50;
}

.subscribe-message.error {
    color: #f44336;
} 