.contribution-container {
  padding: 15px;
  background: transparent;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

}

.contributions-wrapper {
  margin-bottom: 15px;
  width: 100%;
  height:30vh;
  overflow-y: scroll;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.contribution-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  min-height: min-content;
}

/* Custom scrollbar for webkit browsers */
.contributions-wrapper::-webkit-scrollbar {
  width: 8px;
}

.contributions-wrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

.contributions-wrapper::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

.contributions-wrapper::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.contribution-square {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #000000;
  background-color: transparent;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .contribution-square {
    width: 10px;
    height: 10px;
  }
  
  .contribution-container {
    padding: 10px;
  }

  .contribution-grid {
    gap: 2px;
  }
}

.contribution-square:hover {
  transform: scale(1.2);
}

/* Contribution intensity levels */
.contribution-square.level-0 {
  background-color: transparent;
  border: 1px solid #000000;
}

.contribution-square.future {
  background-color: transparent;
  border: 1px solid #cccccc;
  cursor: default;
}

.contribution-square.future:hover {
  transform: none;
}

.contribution-square.level-1 {
  background-color: rgba(0, 0, 0, 0.2);
}

.contribution-square.level-2 {
  background-color: rgba(0, 0, 0, 0.4);
}

.contribution-square.level-3 {
  background-color: rgba(0, 0, 0, 0.6);
}

.contribution-square.level-4 {
  background-color: rgba(0, 0, 0, 0.8);
}

.contribution-square.level-5 {
  background-color: #000000;
}

.contribution-legend {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #000000;
  font-size: 0.75rem;
  padding: 10px;
  flex-wrap: wrap;
}

.legend-squares {
  display: flex;
  gap: 3px;
}

.legend-squares .contribution-square {
  cursor: default;
}

.legend-squares .contribution-square:hover {
  transform: none;
}

.loading {
  text-align: center;
  color: #000000;
  padding: 10px;
}

.error-message {
  color: #000000;
  text-align: center;
  padding: 8px;
  margin-bottom: 15px;
}

.contribution-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.modal-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 4px;
  max-width: 400px;
  width: 90%;
  border: 1px solid #000000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  margin-bottom: 15px;
  font-size: 1.1rem;
  font-weight: 500;
}

.modal-body {
  margin-bottom: 20px;
  font-size: 0.9rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  background: #ffffff;
  color: #000000;
  border: 1px solid #000000;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.9rem;
}

.modal-close-button:hover {
  background: #000000;
  color: #ffffff;
} 