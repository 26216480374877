.twitch-container {
  padding: 20px;
  border-radius: 10px;
  background: #18181b;
  color: white;
  margin: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.twitch-container.error {
  background: #392e2e;
  border: 1px solid #ff5c5c;
}

.twitch-container.offline {
  background: #2d2d3d;
  text-align: center;
  padding: 40px 20px;
}

.twitch-container.live {
  background: linear-gradient(135deg, #18181b, #392731);
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.stream-info {
  margin-bottom: 20px;
}

.stream-info h3 {
  color: #ff4d4d;
  margin: 0 0 10px 0;
  font-size: 1.5em;
}

.stream-preview {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.stream-preview img {
  width: 100%;
  height: auto;
  display: block;
}

.watch-button, .twitch-follow-button {
  display: inline-block;
  background: #9147ff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 15px;
  font-weight: bold;
  transition: background-color 0.2s;
}

.watch-button:hover, .twitch-follow-button:hover {
  background: #7d2df9;
}

button {
  background: #9147ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.2s;
}

button:hover {
  background: #7d2df9;
}

.stream-embed {
  background: #000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-embed {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .twitch-container {
    padding: 15px;
  }
  
  .stream-info h3 {
    font-size: 1.2em;
  }
}

@media (min-width: 1200px) {
  .twitch-container.live {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 20px;
  }

  .stream-info {
    grid-column: 1 / -1;
  }

  .chat-embed {
    height: 100%;
  }
} 