.interest-section {
    padding: 2rem;
    margin-top: 2em
  }
  
  .interest-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }
  
  .interest-item {
    background-color: #f0f0f0;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .interest-section {
      padding: 1rem;
      margin-top: 5em;
    }

    .interest-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .cursor {
    animation: blink 0.7s infinite;
  }
  
  @keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }