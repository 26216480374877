.calculator-container {
  padding: 2rem;
}

.calculator-tabs {
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.calculator-tab {
  font-family: monospace;
  font-size: 0.875rem;
  padding: 0.5rem 0.25rem 0.5rem;
  transition: color 0.2s;
  position: relative;
  color: rgba(255, 255, 255, 0.4);
}

.calculator-tab:hover {
  color: rgba(255, 255, 255, 0.6);
}

.calculator-tab.active {
  color: rgba(255, 255, 255, 0.9);
}

.calculator-tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background: rgba(255, 255, 255, 0.9);
}

.calculator-form {
  background: white;
  border-radius: 0.5rem;
  padding: 2rem;
}

.calculator-form h2 {
  font-size: 1.5rem;
  font-family: monospace;
  margin-bottom: 1.5rem;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-family: monospace;
  font-size: 0.875rem;
}

.form-select {
  width: 100%;
  padding: 0.75rem;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-family: monospace;
  font-size: 0.875rem;
}

.form-textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-family: monospace;
  font-size: 0.875rem;
  resize: none;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: monospace;
  font-size: 0.875rem;
}

.button-group {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.button {
  flex: 1;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-family: monospace;
  font-size: 0.875rem;
  cursor: pointer;
}

.button-secondary {
  background: #f3f4f6;
}

.button-primary {
  background: black;
  color: white;
}

.button-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.spinner {
  width: 1rem;
  height: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.item-list {
  margin-top: 2rem;
}

.item-category {
  margin-bottom: 1.5rem;
}

.category-title {
  font-family: monospace;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

.item-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  margin-bottom: 0.5rem;
}

.item-card:hover {
  background: rgba(255, 255, 255, 0.05);
}

.item-details {
  flex: 1;
}

.item-name {
  font-family: monospace;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.9);
}

.item-price {
  font-family: monospace;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.4);
}

.item-description {
  font-family: monospace;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 0.25rem;
}

.item-tags {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.tag {
  font-family: monospace;
  font-size: 0.625rem;
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
}

.tag-essential {
  background: rgba(59, 130, 246, 0.1);
  color: #60a5fa;
}

.tag-monthly {
  background: rgba(168, 85, 247, 0.1);
  color: #c084fc;
}

.tag-asset {
  background: rgba(34, 197, 94, 0.1);
  color: #4ade80;
}

.tag-optional {
  background: rgba(234, 179, 8, 0.1);
  color: #facc15;
}

.item-actions {
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s;
}

.item-card:hover .item-actions {
  opacity: 1;
}

.action-button {
  font-family: monospace;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  color: rgba(255, 255, 255, 0.6);
  transition: color 0.2s;
}

.action-button:hover {
  color: rgba(255, 255, 255, 0.9);
}

.action-button-delete {
  color: #f87171;
}

.action-button-delete:hover {
  color: #ef4444;
}

.error-message {
  font-family: monospace;
  padding: 0.75rem;
  background: rgba(239, 68, 68, 0.05);
  border: 1px solid rgba(239, 68, 68, 0.1);
  border-radius: 0.5rem;
  color: #f87171;
  font-size: 0.875rem;
  text-align: center;
  margin-top: 1.5rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
} 