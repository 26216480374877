.cost-calculator {
  padding: 20px;
  background: white;
  border-radius: 8px;
}

.calculator-header {
  margin-bottom: 20px;
}

.calculator-header h2 {
  font-family: 'Major Mono Display', monospace;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.total-costs {
  display: none;
}

.total-item {
  padding: 10px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.amount {
  font-weight: bold;
  color: #000;
}

.categories-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin: 20px 0;
}

.category-card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.category-card h3 {
  font-family: 'Major Mono Display', monospace;
  font-size: 1.5rem;
  margin-bottom: 15px;
  font-weight: bold;
  letter-spacing: -1px;
}

.items-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.item-row:last-child {
  border-bottom: none;
}

.item-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.item-name {
  font-family: 'Major Mono Display', monospace;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: -1px;
}

.essential-tag {
  background: black;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-family: 'Major Mono Display', monospace;
  text-transform: uppercase;
  margin-left: 8px;
}

.monthly-tag {
  font-family: 'Major Mono Display', monospace;
  color: #666;
  font-size: 0.9rem;
  margin-left: 4px;
}

.delete-button {
  background: none;
  border: none;
  font-size: 1rem;
  color: #666;
  cursor: pointer;
  padding: 4px;
}

.delete-button:hover {
  color: #ff4444;
}

.add-item-form {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
}

.add-item-form h3 {
  font-family: 'Major Mono Display', monospace;
  font-size: 1rem;
  margin-bottom: 15px;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  align-items: end;
}

.form-grid input,
.form-grid select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: 'Major Mono Display', monospace;
}

.checkbox-group {
  display: flex;
  gap: 15px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'Major Mono Display', monospace;
  font-size: 0.9rem;
}

.form-grid button {
  background: #000;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Major Mono Display', monospace;
}

.form-grid button:hover {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .total-costs {
    flex-direction: column;
    gap: 10px;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  .checkbox-group {
    flex-direction: column;
    gap: 10px;
  }
}

.item-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item-name a {
  color: #000;
  text-decoration: none;
  border-bottom: 1px dashed #666;
}

.item-name a:hover {
  color: #666;
}

.item-description {
  font-family: 'Major Mono Display', monospace;
  font-style: italic;
  color: #666;
  font-size: 0.9rem;
  margin-top: 5px;
}

.item-cost {
  font-family: 'Major Mono Display', monospace;
  font-size: 1.2rem;
  font-weight: bold;
}

.asset-tag {
  background: #4a5568;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.7rem;
}

.items-section {
  margin-bottom: 15px;
}

.section-title {
  font-family: 'Major Mono Display', monospace;
  font-size: 1rem;
  color: #666;
  margin: 20px 0 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.total-item {
  padding: 15px;
}

.total-item h4 {
  font-family: 'Major Mono Display', monospace;
  margin-bottom: 10px;
  font-size: 1rem;
}

.cost-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.total-item.need {
  border-left: 3px solid #ff4444;
}

.total-item.have {
  border-left: 3px solid #44ff44;
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
}

.revenue-summary {
  margin-top: 2rem;
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 8px;
}

.revenue-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.revenue-item {
  padding: 1rem;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.revenue-item h4 {
  margin: 0 0 0.5rem 0;
  color: #666;
}

.revenue-item .amount {
  font-size: 1.25rem;
  font-weight: bold;
  color: #2c5282;
}

.calculator-section {
  background: #f8f9fa;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
}

.calculator-section > div {
  padding: 20px;
}

.calculator-section:hover {
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #eee;
}

.section-header h2 {
  margin: 0;
  font-size: 1.25rem;
}

.expand-icon {
  font-size: 1.5rem;
  color: #666;
  font-weight: bold;
}

.calculator-section.expanded {
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.calculator-section.expanded .section-header {
  border-bottom-color: #ddd;
}

.price-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.price {
  font-weight: bold;
  font-size: 1.1rem;
}

.unit {
  color: #666;
  font-size: 0.9rem;
}

.estimated {
  background: #f0f0f0;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #444;
}

.total-costs,
.revenue-summary,
.categories-grid,
.revenue-products {
  margin: 1rem;
  padding: 1rem;
}

.category-card {
  transition: transform 0.2s ease;
}

.category-card:hover {
  transform: translateY(-2px);
}

.header-content {
  flex: 1;
}

.header-summary {
  display: block;
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.25rem;
  font-family: 'Major Mono Display', monospace;
}

.revenue-actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.add-revenue-button {
  background: #000;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Major Mono Display', monospace;
  transition: opacity 0.2s;
}

.add-revenue-button:hover {
  opacity: 0.9;
}

.revenue-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.revenue-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.revenue-form h3 {
  margin-bottom: 1.5rem;
  font-family: 'Major Mono Display', monospace;
}

.revenue-form .form-grid {
  display: grid;
  gap: 1rem;
}

.revenue-form input,
.revenue-form select,
.revenue-form textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: 'Major Mono Display', monospace;
}

.revenue-form textarea {
  min-height: 100px;
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.form-actions button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Major Mono Display', monospace;
}

.form-actions button[type="button"] {
  background: #eee;
  color: #333;
}

.form-actions button[type="submit"] {
  background: #000;
  color: white;
}

.cost-actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.add-cost-button {
  background: #000;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Major Mono Display', monospace;
  transition: opacity 0.2s;
}

.add-cost-button:hover {
  opacity: 0.9;
}

.modal-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.item-actions {
  display: flex;
  gap: 8px;
  margin-left: 15px;
}

.edit-button,
.delete-button {
  background: none;
  border: none;
  font-size: 1rem;
  color: #666;
  cursor: pointer;
  padding: 4px;
}

.edit-button:hover {
  color: #2c5282;
}

.delete-button:hover {
  color: #ff4444;
}

.cost-summary,
.revenue-summary {
  margin: 1rem;
  padding: 1rem;
}

.cost-grid,
.revenue-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.cost-item,
.revenue-item {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.cost-item.need {
  border-left: 3px solid #ff4444;
}

.cost-item.have {
  border-left: 3px solid #44ff44;
}

.cost-item h4,
.revenue-item h4 {
  font-family: 'Major Mono Display', monospace;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #333;
}

.amount {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
} 