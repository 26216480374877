.matrix-terminal-container{
    /* background-image: url('./../../pictures/sddev.gif');
    background-size: 40%;
    background-color: #000;
    background-repeat: no-repeat;
    background-position: 0% 0%;
    width: 100vw;
    height: 55vh; */
}

.matrix-terminal-animate-initial {
    animation: perspectiveShift 3s ease-in-out;
    position: sticky;
    left: 5vw;
    right: 5vw;
    top: 10vh;
    border-radius: 8px;
    padding: 0px 3px;
width: 80vw;
    background-color: #000;
    font-family: 'Courier New', monospace;
    box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.3);

}

.matrix-terminal-animate-continuous {
    /* animation: perspectiveShift2 9s ease-in-out infinite; */
    position: sticky;
    left: 5vw;
    right: 5vw;
    border-radius: 8px;
width: 80vw;
    padding: 0px 3px;
    background-color: #000;
    font-family: 'Courier New', monospace;
    box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.3);
    transform: perspective(180px) rotate3d(33, -33, -12, 3deg);
}

.terminal-header {
    background-color: #1a1a1a;
    border-bottom: 1px solid #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.terminal-title {
    color: #0f0;
    font-size: 15px;
}

.terminal-buttons {
    display: flex;
    gap: 8px;
}

.terminal-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
}

.close { background-color: #ff5f56; }
.minimize { background-color: #ffbd2e; }
.maximize { background-color: #27c93f; }

.terminal-content {
    height: 50vh;
    overflow-y: auto;
    color: #0f0;
    /* Add scan line effect */
    background: linear-gradient(
        transparent 50%,
        rgba(0, 0, 0, 0.05) 50%
    );
    background-size: 100% 4px;
    scroll-behavior: smooth;
    max-height: 500px; /* or whatever height you prefer */
}

/* Add CRT screen curvature */
.terminal-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
        circle at center,
        transparent 50%,
        rgba(0, 0, 0, 0.3) 100%
    );
    pointer-events: none;
}

/* Add screen flicker animation */
@keyframes crtFlicker {
    0% { opacity: 0.97; }
    50% { opacity: 1; }
    100% { opacity: 0.98; }
}

.terminal-line {
    display: flex;
    align-items: center;
    line-height: 1.2;
    margin: 2px 0;
}

.terminal-input {
    flex: 1;
    background: transparent;
    border: none;
    color: #33ff33;
    font-family: monospace;
    font-size: 15px;
    margin-left: 8px;
    outline: none;
    padding: 0;
    text-shadow: 0 0 1px rgba(51, 255, 51, 0.4),
                 0 0 2px rgba(51, 255, 51, 0.3),
                 0 0 3px rgba(51, 255, 51, 0.2);
    animation: textShadow 1.6s infinite;
}

.prompt {
    color: #129112;
    margin-right: 10px;
    font-weight: bold;
    font-size: 15px;
}

.text {
    color: #129112;
    font-size: 15px;
    text-shadow: 0 -23px 4px #FFF, 0 26px 10px #ff0, 0 -22px 24px #ff8000, 0 -18px 40px #F00;
    animation: textShadowFade 9s ease-in-out infinite;
}

.terminal-cursor {
    display: inline-block;
    width: 10px;
    height: 18px;
    background-color: #129112;
    animation: blink 0.8s step-end infinite;
    box-shadow: 0 0 8px #00ff00;
}

@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

@keyframes phosphorFade {
    from { text-shadow: 0 0 8px rgba(0, 255, 0, 0.8); }
    to { text-shadow: 0 0 5px rgba(0, 255, 0, 0.5); }
}

.terminal-content::-webkit-scrollbar {
    width: 3px;
}

.terminal-content::-webkit-scrollbar-track {
    background: #0a0a0a;
}

.terminal-content::-webkit-scrollbar-thumb {
    background: #129112;
    border-radius: 4px;
}

.terminal-content::-webkit-scrollbar-thumb:hover {
    background: #129112;
}

/* Add screen flicker animation */
.matrix-terminal::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 255, 0, 0.02);
    pointer-events: none;
    animation: flicker 0.15s infinite;
    z-index: 2;
}

@keyframes flicker {
    0% { opacity: 0.1; }
    50% { opacity: 0.2; }
    100% { opacity: 0.1; }
}

/* Add new perspective shift animation */
@keyframes perspectiveShift {
    0% {  transform: perspective(180px) rotateX(-9deg); }
    50% { transform: perspective(3px) rotateX(-180deg); }
    100% { transform: perspective(180px) rotateX(-9deg) }
} 

@keyframes perspectiveShift2 {
    0% { transform: perspective(180px) rotate3d(-33, -62, -25, -9deg); }
    50% { transform: perspective(180px) rotate3d(33, 62, 25, 0deg); }
    100% { transform: perspective(180px) rotate3d(-33, -62, -25, -9deg); }
} 

@keyframes textShadowFade {
    0% { 
        text-shadow: 0 -23px 4px rgba(255, 255, 255, 0), 
                     0 26px 10px rgba(255, 255, 0, 0), 
                     0 -22px 24px rgba(255, 128, 0, 0), 
                     0 -18px 40px rgba(255, 0, 0, 0);
    }
    50% {
        text-shadow: 0 -23px 4px rgba(255, 255, 255, 0.7), 
                     0 26px 10px rgba(255, 255, 0, 0.7), 
                     0 -22px 24px rgba(255, 128, 0, 0.7), 
                     0 -18px 40px rgba(255, 0, 0, 0.7);
    }
    100% {
        text-shadow: 0 -23px 4px rgba(255, 255, 255, 0), 
                     0 26px 10px rgba(255, 255, 0, 0), 
                     0 -22px 24px rgba(255, 128, 0, 0), 
                     0 -18px 40px rgba(255, 0, 0, 0);
    }
}

.user-message {
    color: #129112;
    font-size: 15px;
    text-shadow: 0 -23px 4px #FFF, 0 26px 10px #ff0, 0 -22px 24px #ff8000, 0 -18px 40px #F00;
    animation: textShadowFade 9s ease-in-out infinite;
}

/* If you don't already have this animation keyframe, add it */
@keyframes textShadow {
  0% {
    text-shadow: 0.4389924193300864px 0 1px rgba(0,30,255,0.5), -0.4389924193300864px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  5% {
    text-shadow: 2.7928974010788217px 0 1px rgba(0,30,255,0.5), -2.7928974010788217px 0 1px rgba(255,0,80,0.3), 0 0 3px;
  }
  /* ... you can keep the existing animation keyframes if you already have them ... */
}

/* Add styles for disabled input during typing */
.terminal-input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.empty-line {
  height: 1.2em;
  display: block;
}

.user-message.success {
  color: #33ff33;
  text-shadow: 0 0 1px rgba(51, 255, 51, 0.6),
               0 0 2px rgba(51, 255, 51, 0.4),
               0 0 3px rgba(51, 255, 51, 0.3);
}

.user-message.error {
  color: #ff3333;
  text-shadow: 0 0 1px rgba(255, 51, 51, 0.6),
               0 0 2px rgba(255, 51, 51, 0.4),
               0 0 3px rgba(255, 51, 51, 0.3);
}


@media (max-width: 668px) {
  .matrix-terminal-container {
    background-image: none;
  }
  .matrix-terminal-animate-initial {

    position: sticky;
    left: 5vw;
    right: 5vw;
    top: 10vh;
    border-radius: 8px;
    width: 100%;
    max-width: 85vw;
    background-color: #000;
    font-family: 'Courier New', monospace;
    box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.3);

}

.matrix-terminal-animate-continuous {

    position: sticky;
    left: 5vw;
    right: 5vw;
    border-radius: 8px;
    width: 100%;
    max-width: 85vw;
    background-color: #000;
    font-family: 'Courier New', monospace;
    box-shadow: inset 0 0 10px rgba(0, 255, 0, 0.3);

}
}