.route-container {
  display: flex;
  width: 100%;
  gap: 2rem;
}

.route-content {
  flex: 1;
  padding: 1rem;
}

.route-header {
  margin-bottom: 2rem;
  border-bottom: 1px solid #333;
  padding-bottom: 1rem;
}

.route-header h2 {
  color: #000000;
  font-size: 1.5rem;
  margin: 0;
}

.route-body {
  position: relative;
}

@media (max-width: 768px) {
  .route-container {
    flex-direction: column;
    gap: 1rem;
  }

  .route-content {
    padding: 0.5rem;
  }
} 