@import './fonts.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  font-family: 'Major Mono Display', monospace;
  line-height: 1.6;
  background-color: #fff;
  color: black;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  padding: 1rem;
  position: relative;
  height: 10vh;
  margin-bottom: 2rem; /* Adjust as needed */
}

.ascii-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  position: relative;
  gap: 2rem;
}

.footer {
  text-align: center;
  padding: 1rem;
  font-size: 0.8rem;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 2.5rem;
}

.project-cube {
  width: 300px;
  text-align: left;
  margin-bottom: 2rem;
}

.cube-container {
  width: 200px;
  height: 200px;
  margin: 0 auto 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-info {
  font-family: 'Major Mono Display', monospace;
}

.project-info h3 {
  margin-bottom: 0.5rem;
}

.project-info p {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.project-info h4 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.project-info ul {
  list-style-type: none;
  padding-left: 0;
}

.project-info li {
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
}

.project-info a {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #61dafb;
  color: #282c34;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.project-info a:hover {
  background-color: #21a1c4;
}

a {
  color: #61dafb;
  font-family: "Rubik Mono One", monospace;
  font-weight: 400;
  font-style: normal;
}

.tech-stack {
  position: relative;
  overflow: hidden;
  max-width: 420px
}

.tech-stack.animating {
  animation: paintBounce 2s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
  /* background-color: black; */
  background: -webkit-radial-gradient(100% 150%, circle, #000 24%, #fff 25%, #fff 28%, #000 29%, #000 36%, #fff 36%, #fff 40%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 100%), 
                -webkit-radial-gradient(0 150%, circle, #000 24%, #fff 25%, #fff 28%, #000 29%, #000 36%, #fff 36%, #fff 40%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 100%), 
                -webkit-radial-gradient(50% 100%, circle, #fff 10%, #000 11%, #000 23%, #fff 24%, #fff 30%, #000 31%, #000 43%, #fff 44%, #fff 50%, #000 51%, #000 63%, #fff 64%, #fff 71%, rgba(0,0,0,0) 71%, rgba(0,0,0,0) 100%), 
                -webkit-radial-gradient(100% 50%, circle, #fff 5%, #000 6%, #000 15%, #fff 16%, #fff 20%, #000 21%, #000 30%, #fff 31%, #fff 35%, #000 36%, #000 45%, #fff 46%, #fff 49%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%), 
                -webkit-radial-gradient(0 50%, circle, #fff 5%, #000 6%, #000 15%, #fff 16%, #fff 20%, #000 21%, #000 30%, #fff 31%, #fff 35%, #000 36%, #000 45%, #fff 46%, #fff 49%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%), 
                #000;
    background: -moz-radial-gradient(100% 150%, circle, #000 24%, #fff 25%, #fff 28%, #000 29%, #000 36%, #fff 36%, #fff 40%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 100%), 
                -moz-radial-gradient(0 150%, circle, #000 24%, #fff 25%, #fff 28%, #000 29%, #000 36%, #fff 36%, #fff 40%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 100%), 
                -moz-radial-gradient(50% 100%, circle, #fff 10%, #000 11%, #000 23%, #fff 24%, #fff 30%, #000 31%, #000 43%, #fff 44%, #fff 50%, #000 51%, #000 63%, #fff 64%, #fff 71%, rgba(0,0,0,0) 71%, rgba(0,0,0,0) 100%), 
                -moz-radial-gradient(100% 50%, circle, #fff 5%, #000 6%, #000 15%, #fff 16%, #fff 20%, #000 21%, #000 30%, #fff 31%, #fff 35%, #000 36%, #000 45%, #fff 46%, #fff 49%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%), 
                -moz-radial-gradient(0 50%, circle, #fff 5%, #000 6%, #000 15%, #fff 16%, #fff 20%, #000 21%, #000 30%, #fff 31%, #fff 35%, #000 36%, #000 45%, #fff 46%, #fff 49%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%), 
                #000;
    background: radial-gradient(circle at 100% 150%, #000 24%, #fff 25%, #fff 28%, #000 29%, #000 36%, #fff 36%, #fff 40%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 100%), 
                radial-gradient(circle at 0 150%, #000 24%, #fff 25%, #fff 28%, #000 29%, #000 36%, #fff 36%, #fff 40%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 100%), 
                radial-gradient(circle at 50% 100%, #fff 10%, #000 11%, #000 23%, #fff 24%, #fff 30%, #000 31%, #000 43%, #fff 44%, #fff 50%, #000 51%, #000 63%, #fff 64%, #fff 71%, rgba(0,0,0,0) 71%, rgba(0,0,0,0) 100%), 
                radial-gradient(circle at 100% 50%, #fff 5%, #000 6%, #000 15%, #fff 16%, #fff 20%, #000 21%, #000 30%, #fff 31%, #fff 35%, #000 36%, #000 45%, #fff 46%, #fff 49%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%), 
                radial-gradient(circle at 0 50%, #fff 5%, #000 6%, #000 15%, #fff 16%, #fff 20%, #000 21%, #000 30%, #fff 31%, #fff 35%, #000 36%, #000 45%, #fff 46%, #fff 49%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%), 
                #000;
    -webkit-background-origin: padding-box;
    background-origin: padding-box;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    -webkit-background-size: 100px 50px;
    background-size: 93px 3px;
    animation: anit 303ms infinite;
    animation-play-state: infinite;
}


@keyframes anit {
  from {background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;}
  to {background-position: 10 0, 30 0, 90px 10px, 30px 110px, 10 0, 20px 30px;}
}

@keyframes paintBounce {
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1.1);
  }
  70% {
    transform: scaleY(0.9);
  }
  100% {
    transform: scaleY(0);
  }
}

/* Make sure tech items stay visible during animation */
.tech-stack * {
  position: relative;
  z-index: 2;
}

.tech-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.tech-item {
  color: #333;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.tech-item:hover {
  background-color: #333;
  color: #f0f0f0;
}

.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}

.project-cube-wrapper {
  flex: 1;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-h1 {
  flex: 1;
}

.projects-h1 h1 {
  font-size: 2.5rem;
  line-height: 1.2;
  margin: 0;
}

.tech-stack {
  flex: 1;
  max-width: 420px;
}

.mobile-projects {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

/* Single Media Query for All Mobile Styles */
@media (max-width: 768px) {
  /* Header styles */
  .header {
    height: auto;
    min-height: 80px;
    padding: 0.5rem;
    margin-bottom: 1rem;
  }

  .header-container {
    flex-direction: column;
    align-items: center;
  }

  /* ASCII container */
  .ascii-container {
    width: 60px;
    height: 60px;
    top: 0.5rem;
    right: 0.5rem;
  }

  /* Projects styles */
  .main {
    padding: 1rem;
    margin-top: 1rem;
    overflow-y: auto;
  }

  .projects-container {
    margin-top: 1rem;
  }

  .mobile-projects {
    padding: 0.5rem;
  }

  .project-cube {
    width: 100%;
    max-width: 300px;
  }

  .tech-stack {
    width: 100%;
    margin-top: 1rem;
  }

  .projects-h1 {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.content-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.projects-section {
  width: 100%;
  margin-top: 2rem;
}

.desktop-projects {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  padding: 0 2rem;
}

.project-cube-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.navigation-buttons button {
  background: transparent;
  border: 1px solid #000000;
  color: #000000;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.navigation-buttons button:hover {
  background: #000000;
  color: black;
}

.route-container {
  width: 100%;
}

@media (max-width: 768px) {
  .content-wrapper {
    padding: 0.5rem;
    gap: 1rem;
  }

  .projects-section {
    margin-top: 1rem;
  }

  .project-cube-wrapper {
    padding: 0 1rem;
  }

  .mobile-projects {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    padding: 0.5rem;
  }

  .projects-h1 {
    max-width: none;
    text-align: center;
    font-family: "Rubik Mono One", monospace;
  }

  .projects-h1 h1 {
    font-size: 2rem;
  }
}