.route-page {
  height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 20px;
}

.route-page::-webkit-scrollbar {
  width: 8px;
}

.route-page::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.route-page::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.route-page::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.route-section {
  margin-bottom: 2rem;
}
p, ol, ul, li ,div{
    font-family:  monospace;
    list-style: none;
}
.route-section h3 {
  font-family: 'Major Mono Display', monospace;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.route-section p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.route-section ul {
  list-style: none;
  padding-left: 0;
}

.route-section li {
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  position: relative;
}

.route-section li::before {
  content: '•';
  position: absolute;
  left: 0;
}

/* Timeline specific styles */
.timeline {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.timeline-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  gap: 20px;
}

.timeline-date {
  min-width: 80px;
  font-weight: bold;
  color: #666;
}

.timeline-content {
  background: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  flex: 1;
}

.timeline-content h3 {
  margin: 0 0 10px 0;
  color: #333;
}

.timeline-content p {
  margin: 0;
  color: #666;
}

.xpost {
  background-image: url('./../../pictures/xpost.jpg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
}

/* Tech grid styles */
.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.tech-category h4 {
  margin-bottom: 1rem;
  font-family: 'Major Mono Display', monospace;
}

/* Mobile styles */
@media (max-width: 768px) {
  .timeline::before {
    left: 20px;
  }

  .timeline-item {
    flex-direction: column;
  }

  .timeline-date {
    text-align: left;
    padding-left: 50px;
    margin-bottom: 0.5rem;
  }

  .timeline-content {
    padding-left: 50px;
  }

  .timeline-content::before {
    left: 14px;
  }

  .tech-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
} 